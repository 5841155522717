.service_item_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: auto;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    background-color: var(--light-violet);
    margin-top: 20px;
}

.service-container{
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: center;
}
.service-container-content{
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin:auto;
    gap:40px;
    width: 100%;
}
.service-container-content-element{
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin:auto;
    gap:40px;
    width: 70%;
}
.fab {
    position: fixed;
    bottom: 50px;
    right: 150px;
    width: 50px;
    height: 50px;
    color: white;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.3s ease;
    transform-origin: center; /* Указываем, что трансформация происходит от центра */
}

.fab:hover {
    transform: scale(1.2);
}

.service_item_container:hover{

    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.4);

}
.service-option-text{
    flex-grow: 1;
    text-align: center;
}
.service-element {
    height: auto;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--white-color);
    font-size: var(--middle-text);
    gap:40px;
}
@media (max-width: 1000px) {
    .service-container {
        width: 90%;
    }
    .service-container-content{
        width: 100%;
        gap:15px;
    }
    .service-container-content-element{
        width: 100%;
        gap:15px;
    }
    .fab {
        bottom: 20px;
        right: 30px;
    }

    }
