.lazy-list {
    margin-top:30px;
    margin-bottom:20px;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 3px; /* Здесь задается расстояние между элементами */
}
.empty-message {
    text-align: center;
    color: #777;
    font-size: var(--middle-text);
    margin-top: 20px;
}
.loading-message {
    text-align: center;
    color: #777;
    font-size: var(--middle-text);
    margin-top: 20px;
}
