@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.navigation {
  display: flex;
  flex-direction: row;
  justify-self: start;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15%;
  background-color: var(--white-color);
  justify-content: space-between;
  width: 85%;
  z-index: 10;
  height: auto;
  border-bottom: 1px solid black;
}
.navigation-exit{
  padding-right: 15%;
}
.navigation-row{
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: auto;
  gap: 8vh;

}
.navigation.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  animation: slideDown 0.5s ease forwards; /* Анимация появления */
}

.navigation.fixed.hidden {
  animation: slideUp 0.5s ease forwards; /* Анимация скрытия */
}

.nav_image {
  cursor: pointer;
  width: 40px;
  height: auto;
  transition: transform 0.3s ease;
}

.nav_image:hover {
  transform: scale(1.2);
}

@media (max-width: 1000px) {
  .navigation{
    padding-top:10px;
    padding-bottom: 10px;
    padding-left: 5%;
    width: 95%;
  }
  .nav_image {
    width: 30px;
  }
  .navigation-exit{
    padding-right: 5%;
  }
}

/*  .navigation {*/
/*    justify-content: center;*/
/*    gap: 200px;*/
/*    width: 80%;*/
/*    padding: 10px;*/
/*    height: auto;*/
/*    right: auto;*/
/*    margin: auto;*/
/*    bottom: 0;*/
/*    flex-direction: row;*/
/*  }*/

/*  .nav_image {*/
/*    width: 25px;*/
/*    height: auto;*/
/*  }*/
/*}*/
