.library_filter_button {
    position: relative;
    cursor: pointer;
    font-size: var(--middle-text);
    transition: color 0.3s ease;
    flex-grow: 1;
    text-align: center;
    width: auto;
    height: 100%;
    padding: 20px;
    color: black;
    border-bottom: 2px solid transparent; /* Устанавливаем прозрачную границу */

}

.library_filter_container {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: space-between;
}
.library_filter_button::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 0;
    background-color: var(--accent);
    transition: width 0.3s ease;
}

.library_filter_button.active::after {
    width: 100%;
}
