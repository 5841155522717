@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat', monospace;
}
input{
  font-size: var(--middle-text);
  background-color: transparent;
}
:root {
  --back-color: #FF000000;
  --white-color: rgba(251, 251, 251, 0.98);
  --dark-violet: #A952A3;
  --violet: #AF94E7;
  --light-violet: #efeefb;
  --border-block: 10px;
  --border-button: 10px;
  --accent: #ff0000;

  --large-text: 29px;
  --middle-text: 19px;
  --small-text: 15px;
}

@media (max-width: 600px) {
  :root {
    --border-block: 40px;
    --border-button: 40px;
    --large-text: 18px;
    --middle-text: 14px;
    --small-text: 10px;
  }
}
