/* src/css/services/service_list_item.css */

.service-list-item {
    background-color: #ffffff;
    border-radius: 15px;
    padding: 20px 40px;
    margin: 10px 0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.service-list-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: nowrap;
}


.service-list-item-content {
     min-width: 0;
    max-width: 50%;
}

.service-list-item-content p {
    text-align: center;
    padding: 5px;
    font-size: var(--middle-text);    word-wrap: break-word; /* Переносим длинные слова */
    overflow-wrap: break-word; /* Аналогично, переносим слова */
    white-space: normal; /* Позволяет тексту переноситься на новую строку */
    overflow: hidden; /* Обрезаем текст, который выходит за пределы */
    text-overflow: ellipsis; /* Добавляем троеточие для длинного текста */
}

.option-button {
    display: flex;
    flex-direction: row;
    gap: 20px;

}
.option-button img {
    transition: transform 0.3s ease;
    cursor: pointer;
}
.option-button  img:hover {
    transform: scale(1.2);
}

.service-list-item-option {
    display: flex;
    width: auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--light-violet);
    border-radius: 15px;
    margin: 10px 0;
    padding: 10px;
    gap:2px;
    box-shadow: 0px 4px 6px rgb(204, 204, 255);
    cursor: pointer;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.service-list-item-option:hover {
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.4);
}

.service-list-item:hover {
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.4);
}

@keyframes flyOut {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(40%);
        opacity: 0;
    }
}

.service-list-item.removing {
    animation: flyOut 0.6s forwards;
}

.item-details {
    margin-left: 50px;
}
.bold-text{
    font-weight: bold;
}
.regular-text{
    font-weight: normal;
}
.buttons-container{
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.service-list-item-content h2 {
    font-weight: normal;
    font-size: var(--middle-text);
    color:black;
}

.more-button.rotated {
    transform: rotate(180deg);
}

.more-button {
    cursor: pointer;
    height: 20px;
    transition: transform 0.3s ease-in-out;
}

.option-image {
    height: auto;
}

.edit-button {
    height: 25px;
}

.option-image {
    height: 120px;
}

@media (max-width: 1200px) {
    .service-list-item-option {
        gap: 20px;
        padding: 10px 30px;
        margin: 10px 0;
    }
    .option-image {
        height: 100px;
    }
    .more-button {
        height: 15px;
    }
    .edit-button {
        height: 17px;
    }
    .delete-button {
        height: 17px;
    }
    .service-list-item {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
}
