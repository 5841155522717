.activity-page-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 70%;
    font-size: var(--middle-text);
    margin-bottom: 40px;
}

.activity-text {
    text-align: start;
    overflow-wrap: break-word;
    max-width: 45%; /* Предотвращает выход текста за пределы контейнера */
}

.activity-info-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 50px;
}

.activity-info-container-column {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.activity-page-info p {
    width: 100%; /* чтобы p занимал всю ширину контейнера */
    word-wrap: break-word; /* перенос слов */
    white-space: pre-line; /* обычное поведение пробелов */
}

.video-player {
    max-height: 70vh;
    max-width: 40vw;
    z-index: 1;
}

.activity-page {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.download-button {
    width: 70%;
    margin: auto;
}

.activity-image {
    max-height: 70vh;
    max-width: 30vw;
    object-fit: contain;
}

.activity-header-text {
    font-weight: normal;
    font-size: var(--large-text);
}

.activity-header {
    position: fixed;
    top: 0;
    z-index: 999;
    left: 0;
    padding: 10px 40px;
    width: 100%;
    display: flex;
    justify-content: start;
    min-height: 20px;
    background-color: var(--violet);
}

.activity-header-image {
    width: auto;
    height: 30px;
    transition: transform 0.3s ease; /* Добавляем плавный переход */
}

.activity-header-image:hover {
    transform: scale(1.1); /* Увеличиваем изображение на 50% */
}
.loading-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 300px; /* Высота контейнера для прогресс-бара */
    margin-top: 50px;
}

.loading-bar {
    width: 60%;
    height: 20px;
    border-radius: 10px;
    background-color: #f3f3f3; /* Светло-серый фон прогресс-бара */
    color: #ff0000;
}

.activity-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.loading-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 300px; /* Высота контейнера для анимации */
    margin-top: 50px;
}

.loading-text {
    font-size: var(--middle-text);
    font-weight: normal;
    color: #777;
}
@media (max-width: 1000px) {
    .activity-header-image {
        height: 20px;
    }
    .video-player {
        height: auto;
        width: 90%;
    }
    .activity-text {
        width: 90%; /* Увеличиваем ширину текста на мобильных устройствах */
    }
    .activity-info-container{
        flex-direction: column;
    }
    .activity-image {
        max-height: 30vh;
        max-width: none;
    }
    .video-player{
        max-width: none;
    }
    .activity-text {
        max-width: 100%; /* Предотвращает выход текста за пределы контейнера */
    }
    .activity-info-container {
        gap: 20px;
    }
}
