 .list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 20px 40px;

    margin: 10px 0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}
.list-item:hover {

    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.4);
}
.list-item-icon {
    width: 24px;
    height: 24px;
}
 .list-item-content {
     flex-grow: 1; /* Позволяет контейнеру расширяться до доступной ширины */
     min-width: 0; /* Важно для работы переноса текста в flexbox */
 }

 .list-item-content p {
     word-wrap: break-word; /* Переносим длинные слова */
     overflow-wrap: break-word; /* Аналогично, переносим слова */
     white-space: normal; /* Позволяет тексту переноситься на новую строку */
     overflow: hidden; /* Обрезаем текст, который выходит за пределы */
     text-overflow: ellipsis; /* Добавляем троеточие для длинного текста */
     font-weight: bold;
 }
.library_image{
    height: 40px;
}
.lazy-list {
    margin-top:30px;
    margin-bottom:20px;
    display: flex;
    flex-direction: column;
    gap: 3px; /* Здесь задается расстояние между элементами */
}
 @media (max-width: 600px) {
     .list-item {

         padding: 20px 20px;
         gap:10px
     }

 }
