.button{
  width: auto;
  border-radius: var(--border-button);
  padding: 10px 30px;
  height: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--accent);
  font-size: var(--middle-text);
  color: white;
  transition: transform 0.3s ease;

  cursor: pointer;
}
.additional-button{
  border-radius: var(--border-button);
  padding: 10px 30px;
  height: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E2E4F4;
  font-size: var(--middle-text);
  color: white;
  transition: transform 0.3s ease;
  cursor: pointer;
}
.button:hover{
  transform: scale(1.05);

}
.additional-button:hover{
  transform: scale(1.05);
}
@media (max-width: 600px) {
  .button{
    border-radius: 10px;

  }
  .additional-button{
    border-radius: 10px;
  }
}