.search-container {
  width: 100%;
  height: 100%;
display: flex;
  justify-content: end;
  form{
    width: 100%;
  }
 }

.search-input {
  width: 100%;
  border-bottom: 1px solid #9c27b0;
  border-radius: 5px;
  font-size: var(--middle-text);
  transition: border-color 0.3s ease; /* Добавлено плавное изменение цвета */
}

.search-input:focus {
  border-color: var(--accent); /* Изменение цвета рамки при фокусе */
  outline: none; /* Убираем стандартную обводку */
}
.search-input::placeholder {
  font-size: var(--middle-text);
  color: #ccc;
}
