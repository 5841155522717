.library_container {
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: start;
    align-items: start;
}


.library {
    height: auto;
    width: 100%;

    display: flex;
    flex-direction: column;
        align-items: center;
    background-color: var(--white-color);
    font-size: var(--middle-text);
}

.library_container_content{
    display: flex;
    justify-content: center;
     flex-direction: column;
    margin:auto;
    width: 70%;

}


@media (max-width: 1000px) {
    .library_container {
        width: 90%;
        text-align: center;
    }
    .library_filter_container {
        flex-wrap: wrap;
        padding-top: 20px;
        padding-bottom: 20px;


        p{
            padding: 10px;
        }
    }
    .library_container_content{
        width: 80%;

    }
    .library_filter_button {
        height: 100%;
        margin:0;
    }
}
