/* styles.css */
body {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
}
.form-container{
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:20px;
    align-self: center;
    justify-content: center;

}
 .option-change{
    font-size: var(--middle-text);
}
.custom-form{
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: center;
    align-items: center;
}
.container {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    width: 400px;
    margin: 50px auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.error-messages{
    color:red;
}
.progress-bar{
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin:auto;
    padding: 40px;
    align-items: center;

}
.download-file-button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin-bottom: 40px;
    margin-top:40px;
}
h2 {
    color: black;
    text-align: center;
    margin-bottom: 20px;
}

label {
    display: block;

    margin-bottom: 5px;
    font-weight: normal;
    color: black;
}
.preview-image {
    max-width: 100%;
    max-height: 200px;
    width: auto;
    margin-top: 10px;
    object-fit: contain; /* Значение 'contain' сохраняет пропорции и подгоняет изображение по размеру */

}
input[type="text"],
input[type="number"],
input[type="password"],
input[type="file"],
input[type="checkbox"],
input[type="radio"] {
    width: calc(100% - 20px);
    padding: 10px;
    margin-top: 5px;
    border: 0;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--violet);
    font-size: var(--middle-text);
}
input[type="text"]:focus,
input[type="number"]:focus,
input[type="file"]:focus,
input[type="checkbox"]:focus,
input[type="checkbox"]:focus,
input[type="radio"]:focus {
    outline: none; /* Убирает стандартное выделение */
    border-bottom: 2px solid var(--accent); /* Пример изменения цвета рамки при фокусе */
}
input[type="checkbox"],
input[type="radio"] {
    width: auto;
    margin-right: 10px;
}
.primitive-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.primitive-container textarea {
    padding: 8px;
    min-height: 150px;
    font-size: var(--middle-text);
    border: 2px solid var(--violet);
    resize: vertical; /* позволяет пользователю изменять размер TextArea по вертикали */
}
.primitive-container textarea:focus {
    padding: 8px;
    min-height: 150px;
    font-size: var(--middle-text);
    border-bottom: 2px solid var(--accent);
    resize: vertical; /* позволяет пользователю изменять размер TextArea по вертикали */
}
.primitive-container select {
    padding: 8px;
    font-size: var(--middle-text);
}

.custom-button {
    margin-top: 20px;
    background-color: var(--dark-violet);
    width: 150px;
    height: 40px;
    color: white;
    border-radius: var(--border-button);
    text-align: center;
    padding: 8px;
    border: none;
    cursor: pointer;
    position: relative; /* Важное свойство для позиционирования input */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;

}
.custom-button:hover{
    transform: scale(1.05);

}
.custom-button span {
     cursor: pointer;
    pointer-events: none; /* Убираем реакции для текста, чтобы клики шли на input */
}
.file-input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    cursor: pointer;
    z-index: -1;
 }

.custom-form{
    button {
        width: auto;
        padding: 15px  35px;
        background-color: var(--accent);
        display: flex;
        margin:auto;
        color: white;
        border: none;
        border-radius: 25px;
        font-size: var(--middle-text);
        cursor: pointer;
    }

}
.file-name{
    font-size: var(--small-text);
    color: var(--accent)
}
@media (max-width: 600px) {
    .custom-button {
        margin-top: 10px;
        padding: 3px;
    }
    .option-change{
        font-size: var(--small-text);
        width: 50px;
    }
    .primitive-container select {
        padding: 8px;
        width: 85%;
        font-size: var(--middle-text);
    }
}