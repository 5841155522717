 .library_search_panel{
    display: flex;
    align-items: start;
    flex-direction: row;
    justify-content: start;
    gap:40px;
}
.refresh_image {
    cursor: pointer;
    transition: transform 0.3s ease;
    transform-origin: center; /* Указываем, что трансформация происходит от центра */
}

.refresh_image:hover {
    transform: scale(1.2); /* Увеличиваем размер на 20% */
}
 .filter-button{
     width: auto;
     border-radius: var(--border-button);
     padding: 10px 30px;
     height: auto;
     text-align: center;
     display: flex;
     align-items: center;
     justify-content: center;
     background-color: #E2E4F4;
     font-size: var(--middle-text);
     color: #000000;
     transition: transform 0.3s ease;
     gap:5px;

     cursor: pointer;
 }
 .filter-button:hover{
     transform: scale(1.05);

 }
 @media (max-width: 600px) {
     .filter-button{
         border-radius: 10px;

     }
 }
.filter-image {
    cursor: pointer;
    height: 100%;
    transition: transform 0.3s ease;
    transform-origin: center; /* Указываем, что трансформация происходит от центра */
}

.filter-image:hover {
    transform: scale(1.2); /* Увеличиваем размер на 20% */
}
.search_panel{
    width: 100%;
}
.filter-panel {
    display: flex;
    flex-direction: column;
        justify-content: center;
    align-items: center;
    gap:20px;
    background-color: var(--white-color);
    border: 1px solid #000000;
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
    transition: height 0.3s ease-in-out;
    color:black;
    button{
        background-color: var(--accent);
        color:var(--white-color);
        border: none;
        width: 100px;
        border-radius: var(--border-button);
        padding: 10px;
        margin: auto;
    }
}
.filter-category{
    background-color: transparent;
    align-self: start;
}

/* Опциональные стили для блока фильтра */
.filter-panel.open {
    height: auto; /* Высота автоматически подстраивается под содержимое */
}
@media (max-width: 1000px) {
    .library_search_panel {
        width: 100%;
        margin:auto;
        gap:20px
    }
        .refresh_image{
        width: 40px;
    }
    .filter-image{
         width: 35px;

    }
}

 .filter-text {
     display: inline; /* По умолчанию текст отображается */
 }

 @media (max-width: 600px) {
     .filter-text {
         display: none; /* Скрываем текст на мобильных устройствах */
     }

     .filter-button {
         padding: 10px; /* Уменьшаем отступы, чтобы кнопка не была слишком большой */
     }

     .filter-image {
         width: 30px; /* Опционально: уменьшаем размер иконки на мобильных устройствах */
     }
 }