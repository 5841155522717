.header{
    font-weight: normal;
   padding-bottom: 25px;

  padding-top: 25px;
  font-size: var(--large-text);
  width: 100%;
  display: flex;
   justify-content: center;
   align-items: center;
 }
.logo{
    height: 70px;
}
.header-text{
  width: 100%;
  text-align: start;
    font-weight: bold;
}

@media (max-width: 600px) {
  .header-text {
      font-size: var(--large-text);

    padding-left: 0;
     justify-content: center;
  }
  .rounded_image{
     width: 100px;
  }


}
@media (min-width: 1001px) {

    .header{
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

    }

}
@media (max-width: 1001px) {
    .logo{
        height: 50px;
    }
}