.registration-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: center;
    height: 100vh;
    background-size: cover;
}
.error-message {
    color: red;
    text-align: center;
    font-size: var(--small-text);
    margin: 10px 10px 20px;
}

.emocion-logo-big {
    max-width: 400px;
    margin-bottom: 50px;
    padding-left:50px;
    padding-right:50px;
 }
@media (max-width: 1000px) {
    .emocion-logo-big {
        max-width: 150px;
    }
}
.registration-container h1 {
    color: #9c27b0;
    font-size: 2rem;
    margin-bottom: 50px;
}
.registration-container form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.form-group {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin-bottom: 10px;
}
input::placeholder {
    font-size: var(--middle-text);
}

.form-group input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #9c27b0;
    border-radius: 5px;
    font-size: 1rem;
    transition: border-color 0.3s ease; /* Добавлено плавное изменение цвета */
}
.form-group input:focus {
    border-color: var(--accent); /* Изменение цвета рамки при фокусе */
    outline: none; /* Убираем стандартную обводку */
}
.registration-container button {
    margin:auto;
    width: auto;
    padding: 15px 20px;
    background-color: var(--accent);
    font-size: var(--middle-text);
    display: flex;
    color: white;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    transition: transform 0.3s ease;
}
.registration-container label {
    text-align: start;
    font-weight: bold;
    color: black;
    width: 100%;
    margin-bottom: 20px;
}
button:hover {
    transform: scale(1.05);
}
